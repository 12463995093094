import React, { Component, useState, useEffect } from 'react';
import { Button, Form, Message, Divider } from 'semantic-ui-react';
import { useQueryClient } from 'react-query';
import { Switch, Route } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { apiRequest, userProfile, fetchUserProfile, setApiAuthorization } from '../services/ApiClient';
import TermsOfService from './TermsOfService';

const LoginForm = ({ onAuthentication, match, location, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState();
  const [resetLoading, setResetLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const queryClient = useQueryClient();
  const Auth = new AuthService();

  useEffect(() => {
    Auth.logout();
    queryClient.clear();
    if (onAuthentication) onAuthentication(null);
  }, []);

  const handleLogin = e => {
    e.preventDefault();

    if (email.length === 0 || password.length === 0) {
      setErrorMessage('Please enter your email and password.');
      return;
    }

    setLoginLoading(true);

    Auth.login(email, password)
      .then(res => setupUser())
      .catch(err => {
        console.log(err);
        setErrorMessage('Invalid email or password.');
        setLoginLoading(false);
      });
  };

  const handleRequestPasswordReset = e => {
    e.preventDefault();

    if (email.length === 0) {
      setErrorMessage('Please enter your email.');
      return;
    }

    setResetLoading(true);

    Auth.requestPasswordReset(email)
      .then(res => {
        setErrorMessage(null);
        setSuccessMessage('Please check your email to complete resetting your password.');
        setResetLoading(false);
      })
      .catch(err => {
        console.log(err);
        setErrorMessage('No account matching that email was found.');
        setResetLoading(false);
      });
  };

  const handleResetPassword = e => {
    e.preventDefault();

    setLoginLoading(true);

    Auth.resetPassword(match.params['token'], password)
      .then(res => setupUser())
      .catch(err => {
        console.log(err);
        setErrorMessage('An unknown error occurred');
        setLoginLoading(false);
      });
  };

  const setupUser = () => {
    setApiAuthorization(Auth.getToken());
    fetchUserProfile().then(profile => {
      setLoginLoading(false);

      if (onAuthentication) onAuthentication(profile);

      const return_path = location?.state?.from?.pathname;
      const return_search = location?.state?.from?.search;
      if (return_path) {
        history.push(return_path + return_search);
      } else {
        history.push('/');
      }
    });
  };

  const loginInputs = (
    <div>
      <Form.Input
        name="email"
        fluid
        icon="mail"
        iconPosition="left"
        placeholder="E-mail Address"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Form.Input
        name="password"
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <Button primary fluid size="large" loading={loginLoading} onClick={handleLogin}>
        Login
      </Button>
      <Divider />
      <Button fluid size="medium" loading={resetLoading} onClick={handleRequestPasswordReset}>
        Reset Password
      </Button>
    </div>
  );

  const passwordResetInputs = (
    <div>
      <Form.Input
        name="password"
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <Button primary fluid size="large" loading={loginLoading} onClick={handleResetPassword}>
        Update Password
      </Button>
    </div>
  );

  return (
    <div>
      <Form size="large">
        <Switch>
          <Route path="/password_resets" render={() => passwordResetInputs} />
          <Route render={() => loginInputs} />
        </Switch>
      </Form>

      {errorMessage && <Message error>{errorMessage}</Message>}
      {successMessage && <Message success>{successMessage}</Message>}
      <TermsOfService />
    </div>
  );
};

export default LoginForm;
