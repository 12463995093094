import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Responsive, Container, Divider, Sidebar, Button, Icon, Sticky } from 'semantic-ui-react';
import withAnalytics, { initAnalytics } from 'react-with-analytics';
import Welcome from './Welcome';
import NotFound from './NotFound';
import AuthService from './services/AuthService';
import MenuOptions from './MenuOptions';

const About = React.lazy(() => import('./About'));
const OpenMatchesForReferee = React.lazy(() => import('./auction/referee/OpenMatchesForReferee'));
const CreateOfferForReferee = React.lazy(() => import('./auction/referee/CreateOfferForReferee'));
const RefereeDashboard = React.lazy(() => import('./dashboard/RefereeDashboard'));
const HistoricalMatchesForReferee = React.lazy(() => import('./auction/referee/HistoricalMatchesForReferee'));
const MatchesForAdmin = React.lazy(() => import('./auction/admin/MatchesForAdmin'));
const DependentBidsForAdmin = React.lazy(() => import('./auction/admin/DependentBidsForAdmin'));
const HistoricalMatchesForAdmin = React.lazy(() => import('./auction/admin/HistoricalMatchesForAdmin'));
const Settings = React.lazy(() => import('./settings/Settings'));
const Management = React.lazy(() => import('./management/Management'));
const Payment = React.lazy(() => import('./payment/Payment'));
const RateFromToken = React.lazy(() => import('./rating/RateFromToken'));
const ReportTester =
  process.env.NODE_ENV != 'production' ? React.lazy(() => import('./report/ReportTester')) : NotFound;

initAnalytics('UA-132315787-1');

class App extends Component {
  constructor() {
    super();
    this.state = { sidebar_visible: false };
    this.contextRef = React.createRef();
  }

  tryToLoadProfile() {
    const userProfile = Auth.getProfile();
    if (userProfile) {
      Auth.setProfile(userProfile); // initializes error handling and pendo

      this.setState({ userProfile });
    }
  }

  componentDidMount() {
    this.tryToLoadProfile();
  }

  handleShowClick = () => this.setState({ sidebar_visible: true });
  handleSidebarHide = () => this.setState({ sidebar_visible: false });

  render() {
    return (
      <div ref={this.contextRef}>
        <Sidebar.Pushable style={{ minHeight: '100vh', transform: 'none' }}>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Sticky context={this.contextRef.current} pushing>
              <MenuOptions userProfile={this.state.userProfile} />
            </Sticky>
          </Responsive>

          <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
            <Container>
              <Sticky context={this.contextRef.current} pushing>
                <Button icon="bars" onClick={this.handleShowClick} />
              </Sticky>
            </Container>

            <Sidebar
              onHide={this.handleSidebarHide}
              visible={this.state.sidebar_visible}
              width="thin"
              onClick={this.handleSidebarHide}
            >
              <MenuOptions userProfile={this.state.userProfile} mobile={true} />
            </Sidebar>
          </Responsive>

          <Sidebar.Pusher style={{ minHeight: '100vh' }}>
            <Divider hidden />
            <Suspense
              fallback={
                <center>
                  <Icon name="spinner" size="large" loading />
                </center>
              }
            >
              <Switch>
                <StartingRoute path="/" exact />
                <Route
                  path={['/login', '/password_resets/:token', '/signup']}
                  render={props => (
                    <Welcome {...props} onAuthentication={userProfile => this.setState({ userProfile })} />
                  )}
                />
                <PrivateRoute path="/matches/mine" component={RefereeDashboard} />
                <PrivateRoute path="/matches/offers" component={OpenMatchesForReferee} />
                <PrivateRoute path="/bids/:bid_id/create_offer" component={CreateOfferForReferee} />
                <PrivateRoute path="/matches/history" component={HistoricalMatchesForReferee} />
                <PrivateRoute path="/matches/bids" exact component={MatchesForAdmin} />
                <PrivateRoute path="/matches/bids/history" component={HistoricalMatchesForAdmin} />
                <PrivateRoute path="/officials/bids" component={DependentBidsForAdmin} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/management" component={Management} />
                <PrivateRoute path="/payments" component={Payment} />
                <PrivateRoute path="/reports/tester" component={ReportTester} />
                <Route path="/ratings/:rating_id" component={RateFromToken} />
                <Route path="/about" component={About} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const Auth = new AuthService();

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        Auth.loggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function StartingRoute({ ...rest }) {
  if (Auth.loggedIn() && Auth.getProfile().links.open_bids) {
    return <Redirect to="/matches/mine" />;
  } else if (Auth.loggedIn() && Auth.getProfile().links.administrable_teams) {
    return <Redirect to="/matches/bids" />;
  } else if (Auth.loggedIn()) {
    return <Redirect to="/settings" />;
  } else {
    return <Redirect to="/login" />;
  }
}

export default withRouter(withAnalytics(App));
