import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

class MenuOptions extends Component {
  render() {
    return (
      <Menu fluid borderless pointing={!this.props.mobile} vertical={!!this.props.mobile} style={{ height: '100%' }}>
        <Menu.Item>
          <img src="/header_logo.png" style={{ width: '5em' }} alt="RefUp.io" />
        </Menu.Item>

        {this.props.userProfile && this.props.userProfile.links.open_bids && (
          <Menu.Item as={NavLink} to="/matches/mine">
            My Matches
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.open_bids && (
          <Menu.Item as={NavLink} to="/matches/offers">
            My Offers
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.open_bids && (
          <Menu.Item as={NavLink} to="/matches/history">
            Match History
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.administrable_teams && (
          <Menu.Item as={NavLink} to="/matches/bids" exact>
            My Bids
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.dependent_bids && (
          <Menu.Item as={NavLink} to="/officials/bids">
            Coaching Bids
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.administrable_teams && (
          <Menu.Item as={NavLink} to="/matches/bids/history">
            Bid History
          </Menu.Item>
        )}

        {this.props.userProfile && this.props.userProfile.links.payment_transactions && (
          <Menu.Item as={NavLink} to="/payments">
            Payments
          </Menu.Item>
        )}

        {this.props.userProfile &&
          (this.props.userProfile.links.referee_societies || this.props.userProfile.links.create_team) && (
            <Menu.Item as={NavLink} to="/management">
              Management
            </Menu.Item>
          )}

        <Menu.Menu position="right">
          <Menu.Item as="a" href="https://refup.freshdesk.com/support/home" target="_blank">
            Help
          </Menu.Item>

          {this.props.userProfile && (
            <Menu.Item as={NavLink} to="/settings">
              Settings
            </Menu.Item>
          )}

          {this.props.userProfile == null && (
            <Menu.Item as={NavLink} to="/login">
              Login
            </Menu.Item>
          )}

          {this.props.userProfile && (
            <Menu.Item as={NavLink} to="/login">
              Logout
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

export default MenuOptions;
