import React, { Component } from 'react';
import { Grid, Header, Tab } from 'semantic-ui-react';
import LoginForm from './login/LoginForm';
import SignupForm from './login/SignupForm';

class Welcome extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Login',
        render: () => (
          <Tab.Pane>
            <LoginForm {...this.props} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Sign Up',
        render: () => (
          <Tab.Pane>
            <SignupForm {...this.props} />
          </Tab.Pane>
        )
      }
    ];

    let defaultActiveIndex = 0;
    if (this.props.match.url == '/signup') {
      defaultActiveIndex = 1;
    }
    return (
      <div className="login-form">
        <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" textAlign="center">
              Welcome to RefUp!
            </Header>
            <Tab panes={panes} defaultActiveIndex={defaultActiveIndex} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Welcome;
