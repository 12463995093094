import React, { PureComponent } from 'react';
import { Divider } from 'semantic-ui-react';

export default class TermsOfService extends PureComponent {
  render() {
    return (
      <div style={{ fontSize: 'smaller' }}>
        <Divider hidden />
        By accessing this site you agree to the{' '}
        <a href="https://refup.freshdesk.com/support/solutions/articles/44001867331-terms-of-use" target="_blank">
          Terms of Use
        </a>
      </div>
    );
  }
}
