// import decode from 'jwt-decode';
export default class AuthService {
  domain = process.env.REACT_APP_CORE_HOST || window.env.CORE_HOST || 'http://localhost:3001';
  token_expiration_hours = 23.9;

  links = {
    user_token: `${this.domain}/user_token`,
    refresh_token: `${this.domain}/user_token/refresh`,
    reset_password: `${this.domain}/reset_password/reset`,
    request_reset_password: `${this.domain}/reset_password/request`,
    organizations: `${this.domain}/organizations`,
    roles: `${this.domain}/roles`,
    create_user: `${this.domain}/users`,
    create_user_role_organization: `${this.domain}/user_role_organizations`
  };

  login(email, password) {
    const url = this.links.user_token;
    const body = JSON.stringify({ auth: { email: email, password: password } });
    return this.fetchToken(url, body);
  }

  resetPassword(token, password) {
    const url = this.links.reset_password;
    const body = JSON.stringify({ auth: { token: token, password: password } });
    return this.fetchToken(url, body);
  }

  refreshToken() {
    const url = this.links.refresh_token;
    const body = null;
    const headers = { Authorization: 'Bearer ' + this.getToken() };
    return this.fetchToken(url, body, headers);
  }

  fetchToken(url, body, headers = {}) {
    const default_headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    headers = Object.assign(headers, default_headers);

    // Get a token from api server using the fetch api
    return fetch(url, {
      method: 'POST',
      body: body,
      headers: headers
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        this.setToken(res.jwt); // Setting the token in localStorage
        return Promise.resolve(res);
      });
  }

  requestPasswordReset(email) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    // Get a token from api server using the fetch api
    return fetch(this.links.request_reset_password, {
      method: 'POST',
      body: JSON.stringify({ auth: { email: email } }),
      headers: headers
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        return Promise.resolve(res);
      });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    return !!this.getToken() && this.tokenNotExpired();
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
    this.setTokenExpiration();
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token');
  }

  setTokenExpiration() {
    const exp_time = this.token_expiration_hours * 1000 * 60 * 60;
    localStorage.setItem('id_token_exp', Date.now() + exp_time);
  }

  tokenNotExpired() {
    return localStorage.getItem('id_token_exp') > Date.now();
  }

  setProfile(user_profile) {
    localStorage.setItem('user_profile', JSON.stringify(user_profile));
    if (window.errorHandler) {
      window.errorHandler.setUser(user_profile.id);
    }
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user_profile.links.self,
          full_name: user_profile.name
        }
      });
    }
  }

  getProfile() {
    // Retrieves the user token from localStorage
    return JSON.parse(localStorage.getItem('user_profile'));
  }

  getDomain() {
    return this.domain;
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_exp');
    localStorage.removeItem('user_profile');
  }
}
