import React, { useState } from 'react';
import { Button, Form, Message, Icon, Dropdown } from 'semantic-ui-react';
import AuthService from '../services/AuthService';
import { apiRequest, userProfile } from '../services/ApiClient';
import TermsOfService from './TermsOfService';
import fastSort from 'fast-sort';
import useRoles from '../hooks/useRoles';
import useOrganizations from '../hooks/useOrganizations';

const authService = new AuthService();

const ROLE_NAME_MAPPING = {
  Union: 'Competition Administrator',
  'Referee Society': 'Referee Society Administrator',
  Club: 'Club Administrator'
};

const SignupForm = ({}) => {
  let referee_society_path = null;
  const path_parts = window.location.pathname.split('/');
  if (path_parts[2]) {
    referee_society_path = path_parts[2];
  }

  const { roles, rolesLoading } = useRoles();
  const { organizations, organizationsLoading } = useOrganizations({
    referee_society: referee_society_path,
    fetch_all: true
  });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [errors, setErrors] = useState({});
  const [displayButton, setDisplayButton] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const roleOptions = calculateRoleOptions({ roles, rolesLoading });
  const organizationOptions = calculateOrganizationOptions({
    organizations,
    organizationsLoading,
    roles,
    selectedRole
  });

  const validateForm = () => {
    let error_messages = [];
    let errors = {};
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      error_messages.push('Please enter a valid email address.');
      errors.email = true;
    }
    if (!/^\S+\ +\S/.test(name)) {
      error_messages.push('Please enter your first and last name.');
      errors.name = true;
    }
    if (password.length < 5) {
      error_messages.push('Please enter a password with at least 5 characters.');
      errors.password = true;
    }
    if (selectedRole == '') {
      error_messages.push('Please select a role.');
      errors.role = true;
    }
    if (selectedOrganization == '') {
      error_messages.push('Please select an organization.');
      errors.organization = true;
    }

    if (Object.values(errors).length == 0) {
      setErrorMessage(false);
      setErrors({});
      return true;
    } else {
      setErrorMessage(error_messages.join(' '));
      setErrors(errors);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);

    try {
      const userParams = {
        email,
        password,
        name,
        time_zone: organizations[selectedOrganization].time_zone,
        unscoped_user_role_organizations_attributes: [{ role_id: selectedRole, organization_id: selectedOrganization }]
      };
      const user = await apiRequest(authService.links.create_user, 'POST', { model: 'user' }, userParams);

      setSuccessMessage('Thanks for signing up! You will receive an email when your membership has been approved.');
      setLoading(false);
      setDisplayButton(false);
    } catch (err) {
      console.log(err);
      if (err.email && err.email.detail == 'has already been taken') {
        setErrorMessage('That email address already exists!');
      } else {
        setErrorMessage(
          <span>
            An unknown error occured. Please contact <a href="mailto:help@refup.io">help@refup.io</a>
          </span>
        );
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Form size="large">
        <Form.Input
          name="email"
          fluid
          icon="mail"
          iconPosition="left"
          placeholder="E-mail Address"
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={errors.email}
        />
        <Form.Input
          name="name"
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          error={errors.name}
        />
        <Form.Input
          name="password"
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={errors.password}
        />
        <Form.Field error={errors.role}>
          <div
            className="ui fluid left icon input"
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '.28571429rem',
              lineHeight: '1.21428571em',
              padding: '.67857143em 1em .67857143em 2.67142857em'
            }}
          >
            I am a&nbsp;
            <Dropdown
              name="role"
              inline
              style={{ minWidth: '200px' }}
              icon={<Icon name="dropdown" style={{ float: 'right' }} />}
              options={roleOptions}
              value={selectedRole}
              onChange={(e, d) => setSelectedRole(d.value)}
            />
            <Icon name="cog" />
          </div>
        </Form.Field>
        <Form.Field error={errors.organization}>
          <div
            className="ui fluid left icon input"
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '.28571429rem',
              lineHeight: '1.21428571em',
              padding: '.67857143em 1em .67857143em 2.67142857em'
            }}
          >
            With&nbsp;
            <Dropdown
              name="organization"
              placeholder="Organization"
              inline
              search
              upward
              style={{ minWidth: '200px' }}
              icon={<Icon name="dropdown" style={{ float: 'right' }} />}
              options={organizationOptions}
              onChange={(e, d) => setSelectedOrganization(d.value)}
              value={selectedOrganization}
            />
            <Icon name="group" />
          </div>
        </Form.Field>

        {displayButton && (
          <Button primary fluid size="large" loading={loading} onClick={handleSubmit}>
            Sign Up!
          </Button>
        )}
      </Form>

      {errorMessage && <Message error>{errorMessage}</Message>}
      {successMessage && <Message success>{successMessage}</Message>}

      <TermsOfService />
    </div>
  );
};

export const mapRoleName = roleName => {
  if (ROLE_NAME_MAPPING[roleName]) return ROLE_NAME_MAPPING[roleName];
  return roleName;
};

export const calculateRoleOptions = ({ roles, rolesLoading }) => {
  if (rolesLoading) return;

  return Object.values(roles).map(role => ({ key: role.id, value: role.id, text: mapRoleName(role.name) }));
};

export const calculateOrganizationOptions = ({ organizations, organizationsLoading, roles, selectedRole }) => {
  if (organizationsLoading) return;
  if (!roles) return;
  if (!selectedRole) return;

  const roleName = roles[selectedRole].name;
  let organizationType;
  switch (roleName) {
    case 'Referee':
      organizationType = 'referee_society';
      break;
    case 'Referee Society':
      organizationType = 'referee_society';
      break;
    case 'Union':
      organizationType = 'union';
      break;
    case 'Club':
      organizationType = 'club';
      break;
  }

  const organizationOptions = Object.values(organizations)
    .filter(o => o[organizationType] == true)
    .map(o => ({ key: o.id, value: o.id, text: o.name }));

  return fastSort(organizationOptions).asc('text');
};

export default SignupForm;
