import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiRequest, userProfile } from '../services/ApiClient';
import AuthService from '../services/AuthService';

const QUERY_KEY = 'organizations';

const authService = new AuthService();

const useOrganizations = ({ referee_society, fetch_all } = {}) => {
  const INSTANCE_QUERY_KEY = [QUERY_KEY, { referee_society: referee_society?.id, fetch_all: fetch_all }];

  let organizationUrl;
  if (fetch_all) organizationUrl = authService.links.organizations;
  if (!fetch_all) organizationUrl = userProfile().links.administrable_organizations;

  const fetchRequest = () =>
    apiRequest(organizationUrl, 'GET', {
      include: 'teams,teams.competition',
      referee_society: referee_society
    }).then(response => {
      let newOrganizations = {};
      response.data.map(organization => (newOrganizations[organization.id] = organization));
      return newOrganizations;
    });

  const updateOrganizationRequest = updatedOrganization =>
    apiRequest(
      updatedOrganization.links.organization,
      'PATCH',
      { model: 'organization', include: 'teams,teams.competition' },
      updatedOrganization
    );

  const createOrganizationRequest = newOrganization =>
    apiRequest(organizationUrl, 'POST', { model: 'organization', include: 'teams,teams.competition' }, newOrganization);

  const processMutation = response =>
    queryClient.setQueryData(INSTANCE_QUERY_KEY, oldData => ({
      ...oldData,
      [response.data.id]: response.data
    }));

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(INSTANCE_QUERY_KEY, fetchRequest, {
    structuralSharing: false
  });
  const updateOrganization = useMutation(updateOrganizationRequest, {
    onSuccess: processMutation
  });
  const addOrganization = useMutation(createOrganizationRequest, {
    onSuccess: processMutation
  });

  ////
  //// Teams
  ////

  const updateTeamRequest = updatedTeam =>
    apiRequest(updatedTeam.links.self, 'PATCH', { model: 'team', include: 'club,competition' }, updatedTeam);

  const createTeamRequest = newTeam =>
    apiRequest(userProfile().links.create_team, 'POST', { model: 'team', include: 'club,competition' }, newTeam);

  const updateTeam = useMutation(updateTeamRequest, {
    onSuccess: response => {
      const oldOrganization = Object.values(data).find(
        organization => organization.teams && organization.teams.find(team => team.id == response.data.id)
      );
      oldOrganization.teams = oldOrganization.teams.filter(team => team.id != response.data.id);

      const newOrganization = data[response.data.club.id];
      newOrganization.teams.push(response.data);

      queryClient.setQueryData(INSTANCE_QUERY_KEY, oldData => ({
        ...oldData,
        [newOrganization.id]: newOrganization,
        [oldOrganization.id]: oldOrganization
      }));
    }
  });

  const addTeam = useMutation(createTeamRequest, {
    onSuccess: response => {
      const newOrganization = data[response.data.club.id];
      newOrganization.teams.push({ ...response.data, can_update: true });

      queryClient.setQueryData(INSTANCE_QUERY_KEY, oldData => ({
        ...oldData,
        [newOrganization.id]: newOrganization
      }));
    }
  });

  return {
    organizations: data,
    updateOrganization: updateOrganization.mutateAsync,
    addOrganization: addOrganization.mutateAsync,
    updateTeam: updateTeam.mutateAsync,
    addTeam: addTeam.mutateAsync,
    organizationsLoading: isLoading
  };
};

export default useOrganizations;
