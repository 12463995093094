import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiRequest, userProfile } from '../services/ApiClient';
import AuthService from '../services/AuthService';

const QUERY_KEY = 'roles';

const authService = new AuthService();

const useRoles = () => {
  const fetchRequest = () =>
    apiRequest(authService.links.roles, 'GET').then(response => {
      let roles = {};
      response.data.map(role => (roles[role.id] = role));
      return roles;
    });

  const { data, isLoading } = useQuery(QUERY_KEY, fetchRequest);

  return { roles: data, rolesLoading: isLoading };
};

export default useRoles;
