import StackdriverErrorReporter from 'stackdriver-errors-js';

if (process.env.NODE_ENV == 'production') {
  var errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: 'AIzaSyCa17itYZ315R2FGtShP5166jUMcEYkmoo',
    projectId: 'referee-221617'
  });
  window.errorHandler = errorHandler;
}
