export default function(registration) {
  const waitingServiceWorker = registration.waiting;

  if (waitingServiceWorker) {
    // reload the page when a service worker becomes active
    waitingServiceWorker.addEventListener('statechange', event => {
      console.log('waiting servic worker statechange', event);
      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });

    // when a user navigates, move them to the next page and
    // make the new service worker active
    const pushState = window.history.pushState;
    console.log('overwriting pushState');
    window.history.pushState = function() {
      console.log('new pushState called with', arguments);
      console.log('waiting service worker', waitingServiceWorker);
      pushState.apply(window.history, arguments);
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    };
  }
}
